<template>
    <div>
        <b-row>
            <b-col md="6">
                <div>
                    <h1>Our Servises</h1>
                </div>
                <div class="mt-3">
                    <span>
                        Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
                    </span>
                </div>
                <div class="mt-3">
                    <b-button variant="primary">Learn More</b-button>
                </div>
            </b-col>
            <b-col md="6" class="hide-on-mobile">
                <MainAnimation />
            </b-col>
        </b-row>
        <div class=""><h1>What we offer</h1></div>
        <b-row class="mt-3">
            <!-- <b-col md="3">
                <offer-card
                    icon="HomeIcon"
                    exploreTo="/our-services/marketing-services"
                    topic="Marketing"
                    description="Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular."
                ></offer-card>
            </b-col> -->

            <b-col md="4">
                <offer-card
                    icon="CodeIcon"
                    exploreTo="/our-services/development-services"
                    topic="Development"
                    description="Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular."
                ></offer-card>
            </b-col>

            <b-col md="4">
                <offer-card
                    icon="BookOpenIcon"
                    exploreTo="/our-services/token-audits"
                    topic="Token Audits"
                    description="Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular."
                ></offer-card>
            </b-col>

            <b-col md="4">
                <offer-card
                    icon="UserCheckIcon"
                    exploreTo="/our-services/company-registration"
                    topic="Company registration"
                    description="Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular."
                ></offer-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton
} from "bootstrap-vue";
import OfferCard from './servises/offerCard.vue'
import MainAnimation from './servises/MainAnimation.vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        OfferCard,
        MainAnimation
    }
    
}
</script>

<style>
@media (max-width: 1024px) {
    .hide-on-mobile {
        display: none;
    }
}

</style>