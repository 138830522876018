<template>
    <b-card class="offer-card">
        <feather-icon 
            :icon="icon"
            size="35"
        />
        <div class="mt-2 mb-2">
            <h4>{{topic}}</h4>
        </div>
        <span>
            {{description}}
        </span>
        <div class="mt-3">
            <b-link :to="exploreTo" class="explore-to">
                Explore More 
                <feather-icon 
                    icon="ArrowRightIcon"
                    size="20"
                />
            </b-link>
        </div>
        
    </b-card>
</template>

<script>
import {
  BCard,
  BLink
} from "bootstrap-vue";

export default {
    props: ['icon', 'topic', 'description', 'exploreTo'],
    components: {
        BCard,
        BLink
    }
}
</script>

<style>
    .offer-card {
        background: #070859 !important;
    }
    .offer-card:hover{
        background: #393AF8 !important;
    }
    .explore-to {
        color: white !important;
    }
</style>